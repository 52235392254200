/** @format */

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.sections {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 4rem;
}

.section-btn {
	background-color: #264653;
	color: white;
	padding: 1rem 2rem;
	border: none;
	border-radius: 0.5rem;
	cursor: pointer;
}

.section-btn:hover,
.section-btn.selected {
	background-color: #2a9d8f;
}

.section-btn:focus {
	outline: none;
}

.seats-wrapper {
	padding-bottom: 4rem;
}

.seat {
	cursor: pointer;
}

.seat:hover,
.seat.selected {
	fill: #e76f51;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.loading {
	width: 100vw;
	height: 80vh;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Spinner */
.lds-grid {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-grid div {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #fff;
	animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
	top: 8px;
	left: 8px;
	animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
	top: 8px;
	left: 32px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
	top: 8px;
	left: 56px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
	top: 32px;
	left: 8px;
	animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
	top: 32px;
	left: 32px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
	top: 32px;
	left: 56px;
	animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
	top: 56px;
	left: 8px;
	animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
	top: 56px;
	left: 32px;
	animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
	top: 56px;
	left: 56px;
	animation-delay: -1.6s;
}
@keyframes lds-grid {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}
